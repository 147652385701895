<template>
  <WidgetFrame>
    <template v-slot:title>
      My Tasks
    </template>
    <template v-slot:content>
      <MyTaskList class="task-list" :userEmail="userEmail" @editTask='editTask'/>
    </template>
  </WidgetFrame>
</template>
<script>
  import { taskService } from '@/services';
  import { formatDate } from '@/helpers';

  export default {
    name: 'HomeMyTasksWidget',
    components: {
      MyTaskList: () => import('@/components/Dashboard/Widget/List/MyTaskList.vue'),
      WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    },
    props: {
      userEmail:    { type: String, required: true },
    },
    watch: {
      
    },
    data() {
      return {
      };
    },
    created() {
      
    },
    methods: {
      editTask(params) {
        this.$emit('editTask', params);
      }
    }
  }
</script>

<style scoped>
  .task-list {
    flex-grow: 1;
  }
</style>